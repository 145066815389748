<template>
    <!-- 单树穿梭框SingleTreeTransfer：左侧树结构，右侧列表 -->
    <a-transfer class="mcd-transfer" ref="singleTreeTransfer" show-search :locale="localeConfig"
        :titles="['所有城市', '已选城市']" :data-source="transferDataSource" :target-keys="target_keys"
        :render="(item) => item.title" :show-select-all="true" @change="handleTransferChange"
        @search="handleTransferSearch">
        <template slot="children" slot-scope="{
          props: { direction, selectedKeys },
          on: { itemSelect, itemSelectAll },
        }">
            <!-- 树形控件 -->
            <a-tree v-if="direction === 'left'" class="mcd-tree" blockNode checkable
                :defaultExpandAll="autoExpandParent" :checked-keys="[...selectedKeys, ...target_keys]"
                :expanded-keys="expandedKeys" :tree-data="treeData" @expand="handleTreeExpanded" @check="(_, props) => {
        handleTreeChecked(
            _,
            props,
            [...selectedKeys, ...target_keys],
            itemSelect,
            itemSelectAll
        );
    }
        " @select="(_, props) => {
        handleTreeChecked(
            _,
            props,
            [...selectedKeys, ...target_keys],
            itemSelect,
            itemSelectAll
        );
    }
        " />
        </template>
    </a-transfer>
</template>

<script>
const dataSource = [
    {
        pid: "0",
        key: "1000",
        label: "黑龙江省",
        title: "黑龙江省",
        children: [
            {
                pid: "1000",
                key: "1028",
                label: "大兴安岭地区",
                title: "大兴安岭地区",
            },
        ],
    },
    {
        pid: "1",
        key: "1100",
        label: "武汉",
        title: "武汉",
        children: [
            {
                pid: "1300",
                key: "1128",
                label: "地区",
                title: "地区",
            },
        ],
    },
]
function isChecked(selectedKeys, eventKey) {
    return selectedKeys.indexOf(eventKey) !== -1;
}
// 树结构转列表结构（深度优先递归）
const dfsTreeToListFn = (tree = [], result = []) => {
    if (!tree?.length) {
        return [];
    }
    tree.forEach((node) => {
        result.push(node);
        node.children && dfsTreeToListFn(node.children, result);
    });
    return result;
};
// 树形结构数据过滤
const filterTree = (tree = [], targetKeys = [], validate = () => { }) => {
    if (!tree.length) {
        return [];
    }
    const result = [];
    for (let item of tree) {
        if (item.children && item.children.length) {
            let node = {
                ...item,
                children: [],
                disabled: targetKeys.includes(item.key),
            };
            for (let o of item.children) {
                if (!validate.apply(null, [o, targetKeys])) continue;
                node.children.push({ ...o, disabled: targetKeys.includes(o.key) });
            }
            if (node.children.length) {
                result.push(node);
            }
        }
    }
    return result;
};
export default {
    data() {
        return {
            localeConfig: {
                itemUnit: "项",
                itemsUnit: "项",
                notFoundContent: "暂无数据",
                searchPlaceholder: "输入关键字进行过滤",
            }, // 语言配置
            expandedKeys: [], // 展开key集合
            target_keys: [], // 已选城市数据的key集合
            keyword: "", // 搜索框关键字
            targetKeys: [],
            dataSource,
            autoExpandParent: true
        };
    },
    computed: {
        treeData() {
            // 树形控件数据源
            const validate = (node, map) => {
                return node.title.includes(this.keyword);
            };
            const result = filterTree(
                this.dataSource,
                this.target_keys,
                validate,
                this.keyword
            );
            return result;
        },
        // 树结构转列表结构
        transferDataSource() {
            return dfsTreeToListFn(this.dataSource);
        },
        pidKeys() {
            // 所有pid为0的key集合
            let keys = [];
            if (this.dataSource && this.dataSource.length) {
                keys = this.dataSource.map((item) => item.key);
            }
            return keys;
        },
    },
    watch: {
        targetKeys: {
            handler: function (val) {
                this.target_keys = val;
                this.handleReset();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        // 穿梭框：change事件
        handleTransferChange(targetKeys, direction, moveKeys) {
            // 过滤：避免头部操作栏“全选”将省级key选中至右边
            this.target_keys = targetKeys.filter((o) => !this.pidKeys.includes(o));
            // 选中城市数据：带省级信息返回，满足接口要求
            const validate = (node, map) => {
                return map.includes(node.key) && node.title.includes(this.keyword);
            };
            let toArray = filterTree(this.dataSource, this.target_keys, validate);
            // handleChange回调函数：treeData-左侧树结构数据，toArray-右侧树结构数据，targetKeys-选中城市key集合
            this.$emit("handleChange", this.treeData, toArray, this.target_keys);
            console.log(toArray, '173');
        },
        // 穿梭框：搜索事件
        handleTransferSearch(dir, value) {
            if (dir === "left") {
                this.keyword = value;
            }
        },
        // 树形控件：change事件
        handleTreeChecked(keys, e, checkedKeys, itemSelect, itemSelectAll) {
            const {
                eventKey,
                checked,
                dataRef: { children },
            } = e.node;
            if (this.pidKeys && this.pidKeys.includes(eventKey)) {
                // 父节点选中：将所有子节点也选中
                let childKeys = children ? children.map((item) => item.key) : [];
                if (childKeys.length) itemSelectAll(childKeys, !checked);
            }
            itemSelect(eventKey, !isChecked(checkedKeys, eventKey)); // 子节点选中
        },
        // 树形控件：expand事件
        handleTreeExpanded(expandedKeys) {
            this.expandedKeys = expandedKeys;
        },
        // 清除事件
        handleReset() {
            this.keyword = "";
            this.$nextTick(() => {
                // 搜索框关键字清除
                const ele = this.$refs.singleTreeTransfer.$el.getElementsByClassName(
                    "anticon-close-circle"
                );
                if (ele && ele.length) {
                    ele[0] && ele[0].click();
                    ele[1] && ele[1].click();
                }
                // 滚动条回到顶部
                if (this.$el.querySelector(".mcd-tree")) {
                    this.$el.querySelector(".mcd-tree").scrollTop = 0;
                }
            });
        },
    },
};
</script>

<!-- <style lang="scss">
  $px: 1px;
  .mcd {
    &-transfer {
      .ant-transfer-list {
        flex: 1;
      }
      .ant-transfer-list-content {
        height: 660 * $px;
        overflow-y: auto;
        margin: 12 * $px 12 * $px 0 0;
      }
    }
    &-tree {
      height: 660 * $px;
      overflow-y: auto;
    }
  }
  </style> -->