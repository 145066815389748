var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-transfer',{ref:"singleTreeTransfer",staticClass:"mcd-transfer",attrs:{"show-search":"","locale":_vm.localeConfig,"titles":['所有城市', '已选城市'],"data-source":_vm.transferDataSource,"target-keys":_vm.target_keys,"render":(item) => item.title,"show-select-all":true},on:{"change":_vm.handleTransferChange,"search":_vm.handleTransferSearch},scopedSlots:_vm._u([{key:"children",fn:function({
      props: { direction, selectedKeys },
      on: { itemSelect, itemSelectAll },
    }){return [(direction === 'left')?_c('a-tree',{staticClass:"mcd-tree",attrs:{"blockNode":"","checkable":"","defaultExpandAll":_vm.autoExpandParent,"checked-keys":[...selectedKeys, ..._vm.target_keys],"expanded-keys":_vm.expandedKeys,"tree-data":_vm.treeData},on:{"expand":_vm.handleTreeExpanded,"check":(_, props) => {
    _vm.handleTreeChecked(
        _,
        props,
        [...selectedKeys, ..._vm.target_keys],
        itemSelect,
        itemSelectAll
    );
},"select":(_, props) => {
    _vm.handleTreeChecked(
        _,
        props,
        [...selectedKeys, ..._vm.target_keys],
        itemSelect,
        itemSelectAll
    );
}}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }